import React, { useEffect, useState } from "react";
import Accordion from "./Accordion";
import PriceAccordion from "./PriceAccordion";
// import "./style.css";
import "./FilterPanel.css";

export const SidePanel = ({
  products,
  setFilteredProducts,
  searchedItem,
  selectedCategory,
  setSelectedCategory,
  sortKey,
  setCurrentPage
}) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedSizes, setSelectedSizes] = useState(new Set());
  const [selectedCondition, setSelectedCondition] = useState(new Set());

  const handleFilterChange = (type, value, isChecked) => {
    const newSet = new Set(type === "size" ? selectedSizes : selectedCondition);
    if (isChecked) {
      newSet.add(value);
    } else {
      newSet.delete(value);
    }
    type === "size" ? setSelectedSizes(newSet) : setSelectedCondition(newSet);
  };

  const categories = [
    { name: "ALL", value: "" },
    { name: "T-SHIRTS", value: "Tops" },
    { name: "BOTTOMS", value: "Bottoms" },
    { name: "OUTERWEAR", value: "Outer" },
    { name: "ACCESSORIES", value: "Accessories" },
    { name: "SNEAKERS", value: "Footwear" },
    { name: "HOUSEWARE", value: "Homeware" },
    { name: "TOYS", value: "Toys" },
    { name: "WATCHES", value: "Watches" },
    { name: "COLLECTIBLES", value: "Collectibles" },
    { name: "HATS", value: "Hats" },
    { name: "BAGS", value: "Bags" },
  ];

  const sortKeys = [
    {
      name: "PRICE +",
      value: "priceUp",
      filters: null,
    },
    {
      name: "CONDITION +",
      value: "condition",
      filters: ["Poor", "Used", "Fairly Good", "Gently Used", "Mint", "New"],
      filterFunction: (condition, isChecked) =>
        handleFilterChange("condition", condition, isChecked),
    },
    {
      name: "SIZE +",
      value: "size",
      filters: ["S", "M", "L", "XL", "XXL", "ONE SIZE", "DEFAULT"],
      filterFunction: (size, isChecked) =>
        handleFilterChange("size", size, isChecked),
    },
  ];

  useEffect(() => {
    if (products.length) {
        setFilteredProducts(filterAndSortProducts());
        setCurrentPage(1);
        console.log("searched item: " + searchedItem);
    }
  }, [
    sortKey,
    products,
    minPrice,
    maxPrice,
    selectedSizes,
    selectedCategory,
    selectedCondition,
    // setFilteredProducts,
    searchedItem,
  ]);

  function sortProducts(list) {
    return [...list].sort((a, b) => {
      if (sortKey === "Low to High") {
        return a.price - b.price;
      } else if (sortKey === "High to Low") {
        return b.price - a.price;
      } else if (sortKey === "last_updated" && a.createdOn && b.createdOn) {
        return (b.createdOn._seconds || 0) - (a.createdOn._seconds || 0);
      } else if (sortKey === "condition" && a.condition && b.condition) {
        return a.condition.localeCompare(b.condition);
      }
      return 0;
    });
  }

  function filterAndSortProducts() {
    console.log("filtering and sorting");
    const filtered = products.filter((product) => {
      // Price filter
      const priceMatch =
        (!minPrice || product.price >= parseFloat(minPrice)) &&
        (!maxPrice || product.price <= parseFloat(maxPrice));

      // Size filter
      // We check if any of the product sizes match the selected sizes
      const sizeMatch =
        selectedSizes.size === 0 ||
        product.size.some((item) => selectedSizes.has(item.size));

      // Category filter
      const categoryMatch =
        !selectedCategory || product.category === selectedCategory;

      const conditionMatch =
        selectedCondition.size === 0 ||
        selectedCondition.has(product.condition);

      // Search filter
      // Assuming the search term should match in name, createdByName, or category
      if (!searchedItem) {
        return priceMatch && sizeMatch && categoryMatch && conditionMatch;
      }

      // Currently searched item in the searchBar. Trims, lowercases, and splits into an array of searchedItems.
      const searchedItems = searchedItem.trim().toLowerCase().split(" ");

      /**
       * Finds a search based off of searching every item that is included in the string of searchedItems.
       * For every word considered in searchedItems, it then references the name/category in products.
       * This adds the feature that the searchBar will reference every word in the searchBar regardless of order.
       * Currently searches name, vendor, item type, and description
       */
      const searchMatch = [
        "name",
        "createdByName",
        "category",
        "description",
      ].some(
        (field) =>
          product[field] &&
          searchedItems.every((item) =>
            product[field].toLowerCase().includes(item)
          )
      );

      // Combining all filters
      return (
        priceMatch &&
        sizeMatch &&
        categoryMatch &&
        conditionMatch &&
        searchMatch
      );
    });
    // return sortProducts(filtered);
    const sorted = sortProducts(filtered);
    setFilteredProducts([...sorted]);
    console.log("sorted products:", sorted); // Check the sorted output
    return sorted;
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const clearFilters = () => {
    setSelectedCategory("");
    setSelectedSizes(new Set());
    setSelectedCondition(new Set());
    setMinPrice("");
    setMaxPrice("");
  };

  return (
    <div className="side-panel">
      {/*CATEGORIES*/}
      <div className="text">
        {categories.map((category, index) => (
          <button
            className={`text-button ${selectedCategory === category.value ? 'active' : ''}`}
            onClick={handleCategoryChange}
            value={category.value}
            key={category.value}
            style={{
              animation: "fadeIn 1s ease forwards",
              animationDelay: `${index * 0.03}s`, // 0.5s delay increment for each button
            }}
          >
            {category.name}
          </button>
        ))}
      </div>

      <div className="space"></div>
      {/**/}

      <div className="accordion">
        <PriceAccordion setMinPrice={setMinPrice} setMaxPrice={setMaxPrice} />
        {sortKeys.slice(1).map((key, index) => (
          <Accordion
            title={key.name}
            content={key.filters}
            filterFunction={key.filterFunction}
            selectedItems={
              key.value === "size" ? selectedSizes : selectedCondition
            }
            style={{
              animation: "fadeIn 1s ease forwards",
              animationDelay: `${index * 0.03}s`,
            }}
          />
        ))}
      </div>

      <div className="text">
        <button
          className="text-button"
          onClick={clearFilters}
          style={{
            animation: "fadeIn 1s ease forwards",
            animationDelay: `${10 * 0.03}s`,
          }}
        >
          CLEAR FILTERS
        </button>
      </div>
    </div>
  );
};

export default SidePanel;
