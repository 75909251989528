import React from 'react';
import "./Seller.css";
import Profile from "../images/Default_pfp.png";

const SellerModal = ({ onClose, seller }) => {
    return (
        <div className="seller-modal-overlay" onClick={onClose}>
            <div className="seller-modal-content">
                <div id="sellerDetails">
                    <img id="sellerImage" src={Profile}></img>
                    <h3 id="sellerName">{seller}</h3>
                    <h3 id="rating">☆ ☆ ☆ ☆ ☆</h3>
                    <div id="follow">FOLLOW</div>
                    <div id="horizontal"></div>
                    <h3 id="items"># ITEMS</h3>
                </div>
            </div>
        </div>
    );
};

export default SellerModal;