import React from "react";
import "./SellerDetails.css";
import "../global.css";
import defaultPFP from "../images/Default_pfp.png";
import { useEffect, useState } from "react";

const SellerDetails = ({ curator, product }) => {
  const [curatorData, setCuratorData] = useState(null);

  useEffect(() => {
    if (curator && curator.uid) {
      console.log("Curator:", curator);
      fetch(`https://api.exchvnge.co/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: curator.uid,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCuratorData(data.profile);
          console.log("User:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [curator]);

  return (
    curatorData && (
      <div className="parent">
        <h3>CURATED BY</h3>
        <div id="wrapper">
          <div className="profile-pic">
            <div id="circleImage">
              <img
                id="profile"
                src={curatorData.image || defaultPFP}
                style={{ objectFit: "cover", display: "block" }}
                alt="Profile Picture"
              />
            </div>
          </div>
          <div className="seller-details">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <div className="name">{curatorData.displayname}</div>
              {curator.isLive && <div id="live">LIVE NOW</div>}
            </div>
            <div className="bio">{curatorData.bio}</div>
            <div className="followers">
              <div className="followers">
                FOLLOWERS {curatorData.followers.length}
              </div>
              <div className="followers">
                FOLLOWING {curatorData.following.length}
              </div>
            </div>
            <button class="appLink" id="followLink">FOLLOW IN THE APP</button>
          </div>
        </div>
        {/* <h3 style={{ marginTop: "80px" }}>OTHER ITEMS FROM THIS CURATOR</h3>
        <div id="productsContainer">
          <div id="otherProducts">
            <img className="otherImage" src={product[0]}></img>
          </div>
          <div id="otherProducts">
            <img className="otherImage" src={product[0]}></img>
          </div>
          <div id="otherProducts">
            <img className="otherImage" src={product[0]}></img>
          </div>
        </div> */}
      </div>
    )
  );
};

export default SellerDetails;
