import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProductText from "./components/ProductText";
import ProductCardImage from "./components/ProductCardImage";
import { useAppState } from "./AppStateContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Cart.css";
import "./global.css";
import "./ProductDetails.css";
import payments from "./images/accepted_payments.png";

const Cart = () => {
    const { cart, setCart, darkMode } = useAppState();
    const [total, setTotal] = React.useState(0);
    const [shipping, setShipping] = React.useState(0);
    const [tax, setTax] = React.useState(0);
    const [subtotal, setSubtotal] = React.useState(0);

    const history = useHistory();
    const goToProductPage = (item) => {
        history.push(`/product/${item.id}`);
    };

    useEffect(() => {
        console.log("Cart:", cart);
    }, [cart]);

    useEffect(() => {
        let subtotal = 0;
        cart.forEach((item) => {
            subtotal += item.price;
        });
        setSubtotal(subtotal);
        let shipping = 0;
        setShipping(shipping);
        setTax(subtotal * 0.1);
        setTotal(subtotal + shipping + (subtotal * 0.1));
    }, [cart]);

    const handleRemoveClick = (id) => {
        const newCart = cart.filter((item) => item.id !== id);
        setCart(newCart);
    }

    return (
        <div className={darkMode ? "dark-mode" : ""}>
            <div id="page-container">
                <Header />
                <div id="content-wrap">
                    <div id="cart-header">YOUR BAG</div>
                    <div id="parent-container">
                        <div id="left-container">
                            {cart.length === 0 && <div id="empty-cart">Your cart is empty</div>}
                            <div className="cart-items">
                                {cart.map((item, index) => (
                                    <div key={index} className="cart-item">
                                        <img
                                            src={item.images[0]}
                                            alt={item.description}
                                            style={{ objectFit: "cover" }}
                                            onClick={() => goToProductPage(item)}
                                        />
                                        <ProductText product={item} onClick={() => goToProductPage(item)} />
                                        <div id={"remove-button"} onClick={() => { handleRemoveClick(item.id) }}>X</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div id="right-container">
                            <div className="cart-summary">
                                <div id={"cart-summary"}>ORDER SUMMARY</div>
                                <div className="cart-summary-item">
                                    <div>SUBTOTAL</div>
                                    <div>${subtotal}</div>
                                </div>
                                <div className="cart-summary-item">
                                    <div>SHIPPING</div>
                                    <div>${shipping}</div>
                                </div>
                                <div className="cart-summary-item">
                                    <div>TAX</div>
                                    <div>${tax}</div>
                                </div>
                                <div className="cart-summary-item">
                                    <div style={{ fontWeight: "800", marginTop: "4rem" }}>TOTAL</div>
                                    <div style={{ marginTop: "4rem" }}>${total}</div>
                                </div>
                                <button id="appLink" style={{ marginTop: "2rem" }}>CHECKOUT</button>
                                <div className="accepted-payments">
                                    <img src={payments} alt="accepted payments" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Cart;
