import React from "react";
import { useHistory } from "react-router-dom";
import "./QuickLook.css";
import SellerDetails from "./SellerDetails";

const Modal = ({ onClose, product }) => {
  const history = useHistory();

  const goToProductPage = () => {
    history.push(`/product/${product.id}`);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div id="imageContainer">
          <img
            id="QLImage"
            src={product.images[0]}
            alt={product.name}
            style={{ objectFit: "cover" }}
          />
        </div>
        <div id="productDetails">
          <h2 id="productName">{product.name.toUpperCase()}</h2>
          <h3 id="productPrice">${product.price}</h3>
          <h3 id="productDesc">{product.description}</h3>
          <button id="appBtn" onClick={goToProductPage}>
            GO TO PRODUCT PAGE
          </button>
          <div id="horizontalLine"></div>
          <div id="sellerDetailsQuickLook">
            <SellerDetails
              curator={product.curator[0]}
              product={product}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Modal;
