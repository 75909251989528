import React from "react";
import ProductPrice from "../ProductPrice";
import "./productCard.css";
import { Link } from "react-router-dom";
import heart from "../images/heart-white.png";
import Modal from "./QuickLook";
import SellerModal from "./Seller";

const truncateName = (name, which) => {
  let maxLength = 0;

  if (which === 1) {
    maxLength = 35;
  } else if (which === 2) {
    maxLength = 13;
  }
  return name.length > maxLength ? name.substring(0, maxLength) + "..." : name;
};

const ProductCard2 = ({ product }) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isSellerSelected, setSellerSelected] = React.useState(false);

  const onQuickLookClick = () => {
    setModalOpen(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(false);
  };

  const onSellerClick = () => {
    setSellerSelected(true);
  };

  const closeSellerModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSellerSelected(false);
  };

  if (!product) {
    return <div>no product information</div>;
  }
  return (
    <div className="product-card">
      <Link to={`/product/${product.id}`} style={{ textDecoration: "none" }}>
        <li
          className="product-item"            
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {product.images && product.images.length > 0 ? (
            <div className="product-image">
              <div className="image-container">
                <img
                  className="product-image"
                  src={product.images[0]}
                  alt={product.description}
                  style={{ objectFit: "cover" }}
                />
                {isHovering && (
                  <div className="quick-look">
                    <button
                      className="quick-look-button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onQuickLookClick();
                      }}
                    >
                      QUICK LOOK
                    </button>
                    <button className="heart-button">
                      <img src={heart} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="no-image">No Image Available</div>
          )}

          <div className="product-description">
            <div className="product-name-price">
              <h2 className="product-name">
                {truncateName(product.name.toUpperCase(), 1)}
              </h2>
              <ProductPrice
                className="product-price"
                price={product.price}
                originalPrice={product.originalPrice}
              />
            </div>
            <div className="product-seller-size">
              <div
                className="product-seller"
                onClick={(e) => {
                  e.preventDefault();
                  onSellerClick();
                }}
              >
                {truncateName(product.createdByName.toUpperCase(), 1)}
                <span class="tooltip">SELLER PROFILE</span>
              </div>
              <div className="product-sizes">
                {product.size.map((item, index) => (
                  <div key={index} className="product-size">
                    {item.size}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </li>
      </Link>
      {isModalOpen && <Modal onClose={closeModal} product={product} />}
      {isSellerSelected && (
        <SellerModal
          onClose={closeSellerModal}
          seller={product.createdByName}
        ></SellerModal>
      )}
    </div>
  );
};

export default ProductCard2;
