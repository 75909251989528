import React, { useState } from "react";
// import "./style.css";
import "./FilterPanel.css";

const Accordion = ({ title, setSelectedCategory, setMinPrice, setMaxPrice}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{"PRICE +"}</div>
      </div>
      {isActive && (
        <div className="accordion-content">
          <div id="priceRange">
            <input id="minPrice" type="number" placeholder="Min" onChange={(e) => {setMinPrice(e.target.value)}}/>
            <span id="range">   </span>
            <input id="maxPrice" type="number" placeholder="Max" onChange={(e) => {setMaxPrice(e.target.value)}}/>
          </div>
        </div>
      )}

    </div>
  );
};

export default Accordion;
