import React, {useState, useEffect} from "react";
// import "./components/style.css";
import "./global.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SidePanel from "./components/SidePanel";
import ProductCard2 from "./components/ProductCard2";
import Sort from "./components/Sort";
import { useAppState } from "./AppStateContext";

const Template = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchedItem, setSearchedItem] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [sortKey, setSortKey] = useState("last_updated");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentProducts, setCurrentProducts] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [totalPages, setTotalPages] = useState(1);
    
    const { darkMode, setDarkMode } = useAppState();

    useEffect(() => {
        fetch("https://api.exchvnge.co/products/list")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                if (data.status === "success" && Array.isArray(data.result)) {
                    setProducts(data.result);
                } else {
                    console.error("Received data is not in the expected format:", data);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoading(false);
            });

        console.log(products);
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [itemsPerPage])

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setCurrentProducts(filteredProducts.slice(startIndex, parseInt(endIndex)));
        setTotalPages(Math.ceil(filteredProducts.length / itemsPerPage));
    }, [currentPage, filteredProducts, itemsPerPage]);


    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleSelectChange = (event) => {
        setItemsPerPage(parseInt(event.target.value));
    };

    // const formatDate = (seconds) => {
    //     return new Date(seconds * 1000).toLocaleDateString("en-US");
    // };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={"page" + (darkMode ? " dark-mode" : "")}>
            <Header
                searchedItem={searchedItem}
                setDarkMode={setDarkMode}
                setSearchedItem={setSearchedItem}
            />

            <div className={`category-name ${selectedCategory === "" ? "empty" : ""}`}>

                <h2
                >
                    {selectedCategory.toUpperCase()}
                </h2>
            </div>


            <div className="parent-container">
                <div className="left-container">
                    <SidePanel
                        setFilteredProducts={setFilteredProducts}
                        products={products}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        searchedItem={searchedItem}
                        sortKey={sortKey}
                        setCurrentPage={setCurrentPage}
                    />
                    <div className="vertical-line"></div>
                </div>
                <div className="right-container">
                    <div id="listingOptions">
                        <div id="perPage">
                            <label for="cars">ITEMS PER PAGE</label>
                            <select name="items" id="items" onChange={handleSelectChange}>
                                <option value="12">12</option>
                                <option value="24">24</option>
                                <option value="36">36</option>
                            </select>
                        </div>
                        <Sort sortKey={sortKey} setSortKey={setSortKey}/>
                    </div>
                    <ul
                        className={`products-grid ${
                            filteredProducts.length === 0 ? "empty" : ""
                        }`}
                    >
                        {currentProducts.length > 0 ? (
                            currentProducts.map((product) => (
                                <ProductCard2 product={product}/>
                            ))
                        ) : (
                            <li id="noProducts">No products found.</li>
                        )}
                    </ul>
                    {filteredProducts.length > 0 && (
                        <div className="pagination">
                            <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                PREV
                            </button>
                            <span>
                                {currentPage}   /  {totalPages}
                            </span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                NEXT
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <Footer/>
        </div>
    );

};

export default Template;
