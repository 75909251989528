import React, { useContext } from "react";
import "./Menu.css";
import { useAppState } from "../AppStateContext";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";
import { auth } from "../firebase";
const Menu = ({ isOpen, toggleMenu }) => {
  const { user, setUser, setUserProfile } = useContext(UserContext);
  const { darkMode } = useAppState();
  const menuItemClass = `mode ${darkMode ? "darkModeMenu_item" : "menu_item"}`;
  const { handleSignOut } = useAppState();

  return (
    <>
      {isOpen && (
        <div
          className={`mode ${darkMode ? "darkmode-menu" : "menu-container"}`}
        >
          <div className="menu-header">
            <button className="close-button" onClick={toggleMenu}>
              ×
            </button>
          </div>
          <ul className="menu-list">
            <li className={menuItemClass}>
              <Link
                to="/login"
                onClick={toggleMenu}
                style={{
                  display: "block",
                  height: "100%",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                SIGN UP / LOGIN
              </Link>
            </li>
            <li className={menuItemClass} onClick={toggleMenu}>
              SETTINGS
            </li>
            <li className={menuItemClass}>
              <a
                href="https://apps.apple.com/vn/app/exchvnge-live-shopping/id1586249762?platform=iphone"
                target="_blank"
                rel="noopener noreferrer"
                onClick={toggleMenu}
                style={{
                  display: "block",
                  height: "100%",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                GET THE APP
              </a>
            </li>
            {user && (
              <li className={menuItemClass} onClick={handleSignOut}>
                SIGN OUT
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Menu;
