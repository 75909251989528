import React, { createContext, useContext, useState } from "react";
import { auth } from "./firebase";
// Create Context
const AppStateContext = createContext();

// Provider Component
export const AppStateProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState({}); // Initial state for other shared data
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("darkMode") === "true";
  }); // State for dark mode
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State for login status
  const [userProfile, setUserProfile] = useState(null); // State for user profile
  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        setIsLoggedIn(false);
        setUserProfile(null);
        console.log("User signed out successfully");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };
  // Initialize cart from localStorage or fallback to an empty array
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  // Combine all shared states into a single context value
  const contextValue = {
    sharedState,
    setSharedState,
    darkMode,
    setDarkMode,
    isLoggedIn,
    setIsLoggedIn,
    userProfile,
    setUserProfile,
    handleSignOut,
		cart,
		setCart,
  };

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
};

// Hook to use state
export const useAppState = () => useContext(AppStateContext);
