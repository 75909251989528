import React, { useState, useEffect, useRef, useContext } from "react";
import "./Header.css";
import logo from "../images/exchvngeLogo.png";
import heart from "../images/heart-3.png";
import search from "../images/search-icon.png";
import menuIcon from "../images/menu-bar.png";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import { useAppState } from "../AppStateContext";
import { UserContext } from "../UserContext";

const Header = ({ searchedItem, setSearchedItem }) => {
  const { darkMode, setDarkMode } = useAppState();
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const searchBarRef = useRef(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (isSearchBarVisible) {
      searchBarRef.current.focus();
    }
  }, [isSearchBarVisible]);

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
  };

  const handleToggle = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode);
  };

  const handleItemSearch = (event) => {
    setSearchedItem(event.target.value.toLowerCase());
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id="navbar">
      <Link to="/">
        <img className="logo" alt="Logo" src={logo} />
      </Link>
      <div className="profile-or-login">
        {user ? (
          <span>{user.displayName || "Your Display Name"}</span>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </div>
      <div className="icon-wrapper">
        <img
          className="icon"
          src={search}
          alt="Button Image"
          onClick={toggleSearchBar}
        />
        {isSearchBarVisible && (
          <input
            ref={searchBarRef}
            id="searchBar"
            type="text"
            placeholder="SEARCH ITEMS..."
            value={searchedItem}
            onChange={handleItemSearch}
          />
        )}
        <Link to={"/cart"}>
          <img className="icon" alt="favorites" src={heart} />
        </Link>
        <label className="switch">
          <input onChange={handleToggle} type="checkbox" checked={darkMode} />
          <span className="slider round">
            <div className={`mode ${darkMode ? "dark" : "light"}`}></div>
          </span>
        </label>
        <button onClick={toggleMenu}>
          <img className="icon" alt="menuIcon" src={menuIcon} />
        </button>
      </div>
      {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
      <Menu isOpen={isOpen} toggleMenu={toggleMenu} darkMode={darkMode} />
    </div>
  );
};

export default Header;
