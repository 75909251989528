import React, { createContext, useState, useEffect } from "react";
import { auth } from "./firebase.js";
import { onAuthStateChanged } from "firebase/auth";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedDisplayName = localStorage.getItem("displayName");
    return savedDisplayName ? { displayName: savedDisplayName } : null;
  });

  const [cart, setCart] = useState([]);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        sessionStorage.setItem("uid", firebaseUser.uid);
        fetchUserProfile(firebaseUser.uid);
      } else {
        setUser(null);
        sessionStorage.removeItem("uid");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const savedUid = sessionStorage.getItem("uid");
    if (savedUid) {
      fetchUserProfile(savedUid);
    } else {
      setUser(null); // Ensure user state is null if there's no UID in session storage
    }
  }, []);

  const fetchUserProfile = (uid) => {
    fetch(`https://api.exchvnge.co/user?uid=${uid}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUser({
          uid: data.profile.uid,
          email: data.profile.email,
          displayName: data.profile.displayname,
        });
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, cart, setCart, userProfile, setUserProfile }}
    >
      {children}
    </UserContext.Provider>
  );
};
