// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import ProductsList from './ProductsList';
// import Page from './components/Page';
import DetailsPage from './ProductDetails';
import Template from "./Template"; // Import the product detail page component
import { AppStateProvider } from "./AppStateContext";
import { UserProvider } from './UserContext'; 
import LoginPage from "./components/LoginPage.js";
import Cart from "./Cart";
function App() {
  return (
    <AppStateProvider>
      <UserProvider>
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Template} />
              <Route path="/product/:productId" component={DetailsPage} />
              <Route path="/login" component={LoginPage} />
							<Route path="/cart" component={Cart} />
            </Switch>
          </div>
        </Router>
      </UserProvider>
    </AppStateProvider>
  );
}

export default App;
