import React from "react";
import "./Footer.css";
import apple from "../images/AppStore.png";
import google from "../images/GooglePlay.png";

const Footer = () => {
    return (
        <div className="box">
            <hr/>
            <div className="footer">
                <div className="app-buttons">
                    <a className="app-button" href="https://apps.apple.com/vn/app/exchvnge-live-shopping/id1586249762?platform=iphone"><img src={apple} alt="Download on the App Store"/></a>
                    <a className="app-button" href="https://play.google.com/store/apps/details?id=org.livfie.livfieapp&pcampaignid=web_share"><img src={google} alt="Get it on Google Play"/></a>
                </div>
                <div className="navbar">
                    <a className="navbar-item" href="#">ABOUT US</a>
                    {/*<a className="navbar-item" href="#">RETURN POLICY</a>*/}
                    <a className="navbar-item" href="#">GET THE APP</a>
                    <a className="navbar-item" href="#">BECOME A SELLER</a>
                    <a className="navbar-item" href="#">CONTACT US</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
