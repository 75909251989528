import React, { useEffect } from "react";
import "../global.css"

const Sort = ({ setSortKey, sortKey }) => {
    useEffect(() => {
        console.log("sortKey: ", sortKey);
    }, [sortKey]);

    const togglePriceOrder = () => {
      if (sortKey === "Low to High") {
        setSortKey("High to Low");
      } else {
        setSortKey("Low to High");
      }
    }

  return (
    <div id="sortContainer">
      <p>SORT BY</p>
      <p className="separator">:</p>
      <p className={`sort-text ${(sortKey === "Low to High" || sortKey === "High to Low") ? 'sorted' : ''}`} onClick={togglePriceOrder}>
        PRICE
        {sortKey === 'Low to High' && <span className="triangle">&#9650;</span>}
        {sortKey === 'High to Low' && <span className="triangle">&#9660;</span>}
      </p>
      <p className="separator">|</p>
      {/* <p className={`sort-text ${sortKey === "High to Low" ? 'sorted' : ''}`} onClick={() => setSortKey("High to Low")}>PRICE HIGH TO LOW</p> */}
      {/* <p className="separator">|</p> */}
      <p className={`sort-text ${sortKey === "last_updated" ? 'sorted' : ''}`} onClick={() => setSortKey("last_updated")}>DATE UPDATED</p>
      <p className="separator">|</p>
      <p className={`sort-text ${sortKey === "condition" ? 'sorted' : ''}`} onClick={() => setSortKey("condition")}>CONDITION</p>
    </div>
  );
};

export default Sort;
