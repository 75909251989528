import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SellerDetails from "./components/SellerDetails";
import ImageDetailModal from "./components/ImageDetail";
import "./ProductDetails.css";
import "./global.css";
import { useAppState } from "./AppStateContext";

const DetailsPage = () => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const { productId } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [curator, setCurator] = useState(null);
    const [imageIndex, setImageIndex] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);

    const { darkMode, setDarkMode, cart, setCart } = useAppState();

    const addToCart = () => {
        setCart([...cart, product]);
        console.log("Cart after adding product:", cart);
    }

    useEffect(() => {
        nprogress.start();
        nprogress.configure({ showSpinner: false, speed: 300, minimum: 0.01 });

        fetch(`https://api.exchvnge.co/product?productid=${productId}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    setProduct(data.result);
                    setSelectedImage(data.result.images[0] || null);
                    setCurator(data.result.curator[0]);
                } else {
                    console.error("Product data fetch unsuccessful:", data);
                }
            })
            .catch((error) => {
                console.error("Error fetching product data:", error);
            })
            .finally(() => {
                nprogress.done();
                setLoading(false);
            });
    }, [productId]);

    useEffect(() => {
        if (product && product.images && product.images.length > 0) {
            setSelectedImage(product.images[imageIndex]);
        }
    }, [imageIndex, product]);

    useEffect(() => {
        document.documentElement.classList.toggle('dark-mode', darkMode);
    }, [darkMode]);

    if (loading) {
        return <div className="load-text">Loading product details...</div>;
    }

    if (!product) {
        return <div>Product not found.</div>;
    }

    const handleThumbnailClick = (image, index) => {
        setSelectedImage(image);
        setImageIndex(index);
    };

    const closeModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalOpen(false);
    }

    const handleArrowClick = (direction) => {
        if (direction === "left") {
            if (imageIndex === 0) {
                setImageIndex(product.images.length - 1);
            } else {
                setImageIndex(imageIndex - 1);
            }
        } else {
            if (imageIndex === product.images.length - 1) {
                setImageIndex(0);
            } else {
                setImageIndex(imageIndex + 1);
            }
        }
    };

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        e.target.style.transform = "scale(2)";
        e.target.style.transformOrigin = `${x}% ${y}%`;
    };

    const handleMouseLeave = (e) => {
        e.target.style.transform = "scale(1)";
        e.target.style.transformOrigin = "center center";
    };

    return (
        <div className={darkMode ? "dark-mode" : ""}>
            <Header setDarkMode={setDarkMode} />
            <div id="mainContainer">
                <div id="detailsWrapper">
                    <div id="detailsImage">
                        <div id="large-image" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setModalOpen(true);
                        }}>
                            <img
                                id="selectedImage"
                                src={selectedImage}
                                className="hover-zoom"
                                style={{ objectFit: "cover", overflow: "hidden" }}
                                onMouseMove={handleMouseMove}
                                onMouseLeave={handleMouseLeave}
                            ></img>
                        </div>
                        <div id="arrow-container">
                            <div id="arrow" onClick={() => handleArrowClick("left")}>
                                {"PREV"}
                            </div>
                            <div id="arrow" onClick={() => handleArrowClick("right")}>
                                {"NEXT"}
                            </div>
                        </div>
                        <div id="thumbnailContainer">
                            {product.images.map((image, index) => (
                                <div id="singleImageContainer" key={index}>
                                    <img
                                        id="singleImage"
                                        src={image}
                                        style={{
                                            objectFit: "cover",
                                            overflow: "hidden",
                                            border: selectedImage === image ? (darkMode ? "2px solid white" : "2px solid black") : "none"
                                        }}
                                        onClick={() => handleThumbnailClick(image, index)}
                                        alt={`Thumbnail ${index}`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div id="detailsContainer">
                        <div id="nameContainer">
                            <h3 id="detailName">{product.name.toUpperCase()}</h3>
                        </div>
                        <div id="priceContainer">
                            <h3 id="detailPrice">${product.price}</h3>
                        </div>
                        <h3 style={{ marginBottom: "10px", fontSize: "17px" }}>SIZE</h3>
                        <div id="sizeContainer">
                            <h3 id="detailSize">
                                {product.size.map((item, index) => (
                                    <div key={index} className="product-size">
                                        {item.size.toUpperCase()}
                                    </div>
                                ))}
                            </h3>
                        </div>
                        <button id="appLink" onClick={addToCart}>ADD TO CART</button>
                        <h3 id="detailDesc">{product.description}</h3>
                    </div>
                </div>
                <div id="horizontal-line"></div>
                <SellerDetails curator={curator} product={product.images} />
            </div>
            <Footer />

            {isModalOpen && (
                <ImageDetailModal
                    onClose={closeModal}
                    images={product.images}
                ></ImageDetailModal>
            )}
        </div>
    );
};

export default DetailsPage;
