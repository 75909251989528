import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBPcYPscHd82Fz-QaI2a5oAr5D3PjXxFQ0",
  authDomain: "livfieapp.firebaseapp.com",
  projectId: "livfieapp",
  storageBucket: "livfieapp.appspot.com",
  messagingSenderId: "432207423329",
  appId: "1:432207423329:web:ccd58324cc49745f530720",
  measurementId: "G-XSJYZDP6ZG"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
