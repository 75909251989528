import ProductPrice from "../ProductPrice";
import React from "react";


const ProductText = ({product, onSellerClick}) => {

    const truncateName = (name, which) => {
        let maxLength = 0;
        if (which === 1) {
            maxLength = 35;
        } else if (which === 2) {
            maxLength = 13;
        }
        return name.length > maxLength ? name.substring(0, maxLength) + "..." : name;
    };

    return (
        <div className="product-description">
            <div className="product-name-price">
                <h2 className="product-name">
                    {truncateName(product.name.toUpperCase(), 1)}
                </h2>

                <ProductPrice
                    className="product-price"
                    price={product.price}
                    originalPrice={product.originalPrice}
                />
            </div>
            <div className="product-seller-size">
                <div
                    className="product-seller"
                    onClick={(e) => {
                        e.preventDefault();
                        onSellerClick();
                    }}
                >
                    {truncateName(product.createdByName.toUpperCase(), 1)}
                </div>
                <div className="product-sizes">
                    {product.size.map((item, index) => (
                        <div key={index} className="product-size">
                            {item.size}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ProductText;