import React from 'react';
import "../ProductDetails.css";
import "../global.css";

const ImageDetailModal = ({images, onClose}) => {
    const [selectedImage, setSelectedImage] = React.useState(images[0]);

    return (
        <div className="background" onClick={onClose}>
            <div className="modal">
                <div className="carousel-image-container" onClick={(e) => e.stopPropagation()}>
                    <img
                        id="carousel-image"
                        src={selectedImage}
                    ></img>
                </div>

                <div className={"thumbnailContainer"}>
                    {images.map((image, index) => (
                        <img
                            id="singleImage"
                            key={index}
                            src={image}
                            style={{
                                objectFit: "cover",
                                width: "100px",
                                height: "100px",
                                border:
                                    selectedImage === image ? "2px solid black" : "none",
                                
                            }}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent event from propagating to the background div
                                setSelectedImage(image);
                            }}
                            alt={`Thumbnail ${index}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ImageDetailModal;