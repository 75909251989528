import React from 'react';
import './ProductPrice.css'; // Create and import your CSS file for styling

const ProductPrice = ({price, originalPrice}) => {
    return (
        <div className="product-price">
            {price >= originalPrice ? (
                <span>${originalPrice}</span>
            ) : (
                <>
                    <span className="original-price">${originalPrice}</span>
                    <span>${price}</span>
                </>
            )}
        </div>
    );
};

export default ProductPrice;
