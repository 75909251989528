import React, { useState, useEffect, useContext } from "react";
import "./LoginPage.css";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { UserContext } from "../UserContext";
import { useAppState } from "../AppStateContext";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { user, setUser, setUserProfile } = useContext(UserContext);
  const { darkMode } = useAppState();
  const history = useHistory();

  useEffect(() => {
    document.body.classList.toggle("dark", darkMode);
  }, [darkMode]);

  useEffect(() => {
    if (user) {
      history.push("/"); // Redirect to home if user is already logged in
    }
  }, [user, history]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { accessToken } = userCredential.user;
        fetch(`https://api.exchvnge.co/user?uid=${userCredential.user.uid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            setUser({
              uid: data.profile.uid,
              email: data.profile.email,
              displayName: data.profile.displayname,
            });

            sessionStorage.setItem("uid", userCredential.user.uid);

            setLoading(false);
            history.push("/"); // Redirect to the home page
          })
          .catch((error) => {
            console.error("Error fetching user profile:", error);
            setError("Failed to fetch user profile.");
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  return (
    <div className="page-container">
      <Header />
      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="form-group">Login</h2>
          {error && <div className="error-message">{error}</div>}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="login-button" type="submit" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
