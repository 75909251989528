import React, {useState} from "react";
// import "./style.css";
import "./FilterPanel.css";

const Accordion = ({title, content, filterFunction, selectedItems}) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <div>{title}</div>
            </div>
            {isActive && (
                <div className="accordion-content">
                    <ul>
                        {content.map((filterOption, index) => (
                            <div key={index}>
                                {/*<label htmlFor={`checkbox-${index}`}>{filterOption}</label>*/}
                                {/*<label >{filterOption}</label>*/}
                                <div className={`text-item ${selectedItems.has(filterOption) ? 'selected' : ''}`}
                                     onClick={() => {
                                         filterFunction(filterOption, !selectedItems.has(filterOption));
                                     }}
                                >
                                    {filterOption}
                                </div>
                                {/*<input*/}
                                {/*    type="checkbox"*/}
                                {/*    id={`checkbox-${index}`}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        filterFunction(filterOption, e.target.checked);*/}
                                {/*    }}*/}
                                {/*    checked={selectedItems.has(filterOption)}*/}
                                {/*></input>*/}
                            </div>
                        ))}
                    </ul>
                </div>
                // <div className="accordion-content">
                //   <ul>
                //     {content.map((filterOption, index) => (
                //       <div key={index}>
                //         <label htmlFor={`checkbox-${index}`}>{filterOption}</label>
                //         <input
                //           type="checkbox"
                //           id={`checkbox-${index}`}
                //           onChange={(e) => {
                //             filterFunction(filterOption, e.target.checked);
                //           }}
                //           checked={selectedItems.has(filterOption)}
                //         ></input>
                //       </div>
                //     ))}
                //   </ul>
                // </div>
            )}
        </div>
    );
};

export default Accordion;